<template>
  <section class="main">
    <div class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="table-header">Просмотр категории: {{ this.category.name }}</div>
      <div class="btn-group">
        <a class="btn btn-success" @click="openModal('createSubCategory')">Добавить подкатегорию</a>
        <router-link class="btn btn-secondary" :to="'/edit-category/' + this.id">Редактировать категорию</router-link>
        <router-link class="btn btn-outline-primary" to="/list-category">Назад</router-link>
      </div>
    </div>

    <div class="row">
      <div class="col-md-4">
        <div class="card">
          <div class="card-header">
            Порядок подкатегорий
          </div>
          <div class="card-body-p-0" v-if="this.sortSubCategory.length > 0">
            <Container @drop="onDropCategory">
              <Draggable v-for="(item, index) in this.sortSubCategory" :key="item.id">
                <router-link :to="'/edit-sub-category/' + item.id" class="drag-item">
                  {{ index + 1 }}. {{ item.name }}
                </router-link>
              </Draggable>
            </Container>
          </div>
          <div class="card-body" v-else>
            <p>Подкатегорий не найдено</p>
          </div>
          <div class="card-footer text-center">
            <button class="btn btn-success" @click="saveSortCategory">Сохранить</button>
          </div>
        </div>
      </div>

      <div class="col-md-8">
        <div class="card">
          <div class="card-header d-flex">
            Порядок товаров
            <div class="d-flex ms-auto">
              <div class="form-check form-switch fs-16">
                <input class="form-check-input"
                       type="checkbox"
                       v-model="filter.isAvailable"
                       true-value="1"
                       false-value="0"
                       @change="getIndexInfo">
                <label class="form-check-label">В наличии</label>
              </div>
            </div>
          </div>
          <div class="card-body-p-0 drag-products" v-if="this.sortProducts.length > 0">
            <Container @drop="onDropProduct">
              <Draggable v-for="(item, index) in this.sortProducts" :key="item.id">
                <a class="drag-item">
                  {{ index + 1 }}. {{ item.title }} (Остаток:
                  {{ item.productsAvailable.available > 0 ? item.productsAvailable.available : 0 }})
                </a>
              </Draggable>
            </Container>
          </div>
          <div class="card-body" v-else>
            <p>Товары не найдены</p>
          </div>
          <div class="card-footer text-end">
            <button class="btn btn-success" @click="saveSortProducts">Сохранить</button>
          </div>
        </div>
      </div>
    </div>
    <create-sub-category :id="this.id" v-show="this.$store.state.modal.createSubCategory"/>
  </section>
</template>

<script>
import {Container, Draggable} from "vue-smooth-dnd";
import {applyDrag} from "../../utils/helpers";
import {mapGetters} from "vuex";
import CreateSubCategory from "../../components/modal/category/create-sub-category";

export default {
  name: 'view-category',
  components: {CreateSubCategory, Container, Draggable},
  props: ['id'],
  data: function () {
    return {
      sortSubCategory: [],
      sortProducts: [],
      filter: {
        isAvailable: 0,
      }
    }
  },
  computed: {
    ...mapGetters(['category']),
  },
  async mounted() {
    await this.getIndexInfo();
    this.$root.$on('updateSubCategoriesView', () => {
      this.getIndexInfo();
    });
  },
  methods: {
    getIndexInfo: function () {
      this.$store.dispatch('getCategoriesInfo', {id: this.id, filter: this.filter}).then(() => {
        this.sortSubCategory = this.category.subCategories;
        this.sortProducts = this.category.products;
      });
    },
    saveSortCategory: function () {
      this.$store.dispatch('editSubCategoriesSort', {'sort': this.sortSubCategory, 'cat_id': this.id});
    },
    saveSortProducts: function () {
      this.$store.dispatch('editProductsCategoriesSort', {'sort': this.sortProducts, 'cat_id': this.id,});
    },
    onDropCategory(dropResult) {
      this.sortSubCategory = applyDrag(this.sortSubCategory, dropResult);
    },
    onDropProduct(dropResult) {
      this.sortProducts = applyDrag(this.sortProducts, dropResult);
    },
    openModal: function (name) {
      this.$store.dispatch('openModal', name);
    },
  },
}
</script>

<style lang="scss" scoped>
.drag-products {
  height: 50vh;
  overflow-y: auto;
}

.card-body-p-0 {
  flex: 1 1 auto;
  padding: 0;
}

.drag-item {
  cursor: pointer;
  position: relative;
  display: block;
  padding: .5rem 1rem;
  margin-bottom: -1px;
  background-color: #fff;
}

.smooth-dnd-draggable-wrapper {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &:last-child {
    border-bottom: none;
  }
}
</style>
